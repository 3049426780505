import React from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { Button, Typography } from '@mui/material';

interface ModalProps {
  title: string;
  action: () => void;
  buttonText: string;
  children: React.ReactNode | React.ReactNode[];
}

export const ModalComponent = ({
  children,
  title,
  action,
  buttonText,
}: ModalProps) => {
  return (
    <Modal
      open={true}
      onClose={() => {
        return;
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ModalContent>
          <div>
            <Typography variant='h5'>{title}</Typography>
          </div>
          {children}
          <div>
            <Button fullWidth variant='contained' onClick={action}>
              {buttonText}
            </Button>
          </div>
        </ModalContent>
      </div>
    </Modal>
  );
};

const ModalContent = styled.div`
  background-color: #eee;
  width: 800px;
  max-width: 70%;
  max-height: 70%;
  padding: 24px 16px;

  border-radius: 10px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 32px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 10;
`;
