import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#bfff00',
      contrastText: '#333',
    },
    secondary: {
      main: '#333',
      contrastText: '#fff',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    body1: {
      fontSize: 14,
    },
  },
  components: {
    MuiSpeedDial: {
      styleOverrides: {
        fab: {
          color: 'white',
          backgroundColor: '#333',
          '&:hover': {
            backgroundColor: '#666',
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          backgroundColor: '#333',
          color: '#bfff00',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#bfff00',
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:hover:before': {
            borderColor: '333',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'transparent',
          padding: 0,
        },
      },
    },
  },
});
