import { LeagueType, Team } from './store/DataModel';

import alfa from './assets/f1/icon_alfaromeo.jpeg';
import ferrari from './assets/f1/icon_ferrari.jpeg';
import haas from './assets/f1/icon_haas.jpeg';
import mclaren from './assets/f1/icon_mclaren.jpeg';
import mercedes from './assets/f1/icon_mercedes.jpeg';
import aston_martin from './assets/f1/icon_astonmartin.jpeg';
import redbull from './assets/f1/icon_redbull.jpeg';
import alpine from './assets/f1/icon_alpine.jpeg';
import alpha_tauri from './assets/f1/icon_alphatauri.jpeg';
import williams from './assets/f1/icon_williams.jpeg';

import art from './assets/f2/icon_art.jpeg';
import dams from './assets/f2/icon_dams.jpeg';
import hwa from './assets/f2/icon_hwaracelab.jpeg';
import campos from './assets/f2/icon_campos.jpeg';
import carlin from './assets/f2/icon_carlin.jpeg';
import charouz from './assets/f2/icon_charouz.jpeg';
import hitech from './assets/f2/icon_hitech.jpeg';
import mp from './assets/f2/icon_mpmotorsports.jpeg';
import prema from './assets/f2/icon_prema.jpeg';
import trident from './assets/f2/icon_trident.jpeg';
import uni from './assets/f2/icon_univirtuosi.jpeg';

// F1
export const Teams_F1: Team[] = [
  {
    drivers: [],
    teamLogo: alfa,
    teamName: 'Alfa Romeo',
  },
  {
    drivers: [],
    teamLogo: ferrari,
    teamName: 'Ferrari',
  },
  {
    drivers: [],
    teamLogo: haas,
    teamName: 'Haas',
  },
  {
    drivers: [],
    teamLogo: mclaren,
    teamName: 'McLaren',
  },
  {
    drivers: [],
    teamLogo: mercedes,
    teamName: 'Mercedes',
  },
  {
    drivers: [],
    teamLogo: redbull,
    teamName: 'RedBull',
  },
  {
    drivers: [],
    teamLogo: alpine,
    teamName: 'Alpine',
  },
  {
    drivers: [],
    teamLogo: williams,
    teamName: 'Williams',
  },
  {
    drivers: [],
    teamLogo: aston_martin,
    teamName: 'Aston Martin',
  },
  {
    drivers: [],
    teamLogo: alpha_tauri,
    teamName: 'Alpha Tauri',
  },
];

//   F2
export const Teams_F2: Team[] = [
  {
    drivers: [],
    teamLogo: art,
    teamName: 'ART Grand Prix',
  },
  {
    drivers: [],
    teamLogo: hwa,
    teamName: 'HWA Racelab',
  },
  {
    drivers: [],
    teamLogo: campos,
    teamName: 'Campos Racing',
  },
  {
    drivers: [],
    teamLogo: carlin,
    teamName: 'Carlin',
  },
  {
    drivers: [],
    teamLogo: charouz,
    teamName: 'Charouz Racing System',
  },
  {
    drivers: [],
    teamLogo: dams,
    teamName: 'Dams',
  },
  {
    drivers: [],
    teamLogo: mp,
    teamName: 'MP Motorsport',
  },
  {
    drivers: [],
    teamLogo: prema,
    teamName: 'Prema',
  },
  {
    drivers: [],
    teamLogo: trident,
    teamName: 'Trident',
  },
  {
    drivers: [],
    teamLogo: uni,
    teamName: 'Uni Virtuosi Racing',
  },
];

export const getTeamsByType = (type = LeagueType.F1): Team[] => {
  if (type === LeagueType.F1) {
    return Teams_F1;
  }
  if (type === LeagueType.F2) {
    return Teams_F2;
  }

  return Teams_F1;
};

export const getRandomNumber = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
