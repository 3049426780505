import { Button, Chip, Fab, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { storage } from '../store';
import { LeagueType } from '../store/DataModel';

import F1Logo from '../assets/f1/f1_logo.png';
import F2Logo from '../assets/f2/f2_logo.png';
import DHDLLogo from '../assets/dhdl_logo.png';
import { getRandomNumber } from '../utils';
import { ModalComponent } from '../components/ModalComponent';

export const CreateAndRaffle = observer(() => {
  const store = useContext(storage);
  const [blockedNext, setBlockedNext] = useState<boolean>(false);
  const [showAddDriver, setShowAddDriver] = useState<boolean>(false);
  const [teamRaffleId, setTeamRaffleId] = useState<number>(99);
  const [pw, setPw] = useState<string>('');
  const [driver1, setDriver1] = useState<string>('');
  const [driver2, setDriver2] = useState<string>('');

  const handlePWChange = () => {
    if (pw === process.env.REACT_APP_PW) {
      store.setIsRegistered();
    }
  };

  const enterDriver = (count: number, driver: string) => {
    if (count === 1) {
      setDriver1(driver);
    }
    if (count === 2) {
      setDriver2(driver);
    }
  };

  const saveDriver = () => {
    const drivers = [];
    if (driver1) {
      drivers.push(driver1);
    }
    if (driver2) {
      drivers.push(driver2);
    }

    setDriver1('');
    setDriver2('');

    if (drivers.length > 0) {
      store.addUsers(drivers);
    }
  };

  const startRaffle = () => {
    store.setRaffleMode(true);
    nextRaffle();
  };

  const nextRaffle = () => {
    setTeamRaffleId(99);
    setBlockedNext(true);

    let counter = 0;
    const maxCounter = getRandomNumber(5, 10);

    const interval = setInterval(() => {
      const teamIndex =
        store.raffleData[getRandomNumber(0, store.raffleData.length - 1)];

      if (counter === maxCounter) {
        getRandomDriver(teamIndex);
        clearInterval(interval);
      }

      setTeamRaffleId(teamIndex);
      counter++;
    }, 300);
  };

  const getRandomDriver = (teamIndex: number) => {
    const timeout = setTimeout(() => {
      const random = getRandomNumber(0, Math.max(0, store.drivers.length - 1));
      const drivers = store.drivers[random];

      store.saveTeamAndDriver(teamIndex, drivers);

      setTeamRaffleId(99);
      clearTimeout(timeout);
      setBlockedNext(false);
    }, 1000);
  };

  const newRaffle = () => {
    store.setRaffleMode(false);
    setTeamRaffleId(99);
    store.clear();
  };

  return (
    <>
      {!store.isRegistered && (
        <ModalComponent
          title='Gib das Kennwort ein...'
          buttonText='Freigeben'
          action={handlePWChange}
        >
          <TextField
            label='Kennwort...'
            variant='standard'
            type='password'
            color='secondary'
            value={pw}
            onChange={(e) => setPw(e.currentTarget.value)}
          />
        </ModalComponent>
      )}
      {showAddDriver && (
        <ModalComponent
          title='Trage die Fahrer der Liga ein'
          buttonText='Schließen'
          action={() => setShowAddDriver(false)}
        >
          <AddDriverContainer>
            <TextField
              label='1. Fahrer'
              variant='standard'
              type='text'
              color='secondary'
              value={driver1}
              onChange={(e) => enterDriver(1, e.currentTarget.value)}
              disabled={store.driverEntries === 20}
            />
            <TextField
              label='2. Fahrer'
              variant='standard'
              type='text'
              color='secondary'
              value={driver2}
              onChange={(e) => enterDriver(2, e.currentTarget.value)}
              disabled={store.driverEntries === 20}
            />
            <Button
              variant='contained'
              color='secondary'
              size='small'
              onClick={saveDriver}
              disabled={store.driverEntries === 20}
            >
              Eintragen
            </Button>
          </AddDriverContainer>
          <DriverContainer>
            {store.users.map((u, i) => {
              let label = u[0];

              if (u[1]) {
                label += ` & ${u[1]}`;
              }

              return (
                <Chip
                  color='secondary'
                  label={label}
                  onDelete={() => store.removeUser(i)}
                ></Chip>
              );
            })}
          </DriverContainer>
        </ModalComponent>
      )}
      <Container>
        <LogosContainer>
          <LogoContainer>
            <Logo src={DHDLLogo} alt='DHDL' />
          </LogoContainer>
          {store.teams.map((t, i) => {
            let label = t.drivers[0];
            let label2;
            if (t.drivers[1]) {
              label2 = t.drivers[1];
            }

            return (
              <TeamLogo
                key={t.teamName}
                className={
                  t.drivers.length > 0
                    ? ''
                    : store.raffleMode && i !== teamRaffleId
                    ? 'raffle'
                    : ''
                }
              >
                <img src={t.teamLogo} alt={t.teamName}></img>
                {t.drivers.length > 0 && (
                  <ChipContainer>
                    <Typography variant='subtitle1'>{label}</Typography>
                    <Typography variant='subtitle1'>{label2}</Typography>
                  </ChipContainer>
                )}
              </TeamLogo>
            );
          })}
        </LogosContainer>
        <NavigationContainer>
          <>
            <Fab
              size='medium'
              color='secondary'
              variant='extended'
              onClick={newRaffle}
            >
              Neue Verlosung
            </Fab>

            <MidContainer>
              <Fab
                size='medium'
                color='primary'
                variant='extended'
                onClick={startRaffle}
                disabled={
                  blockedNext ||
                  store.raffleData.length === 0 ||
                  store.drivers.length === 0
                }
              >
                {store.raffleMode ? 'Nächste' : 'Verlosung Starten'}
              </Fab>
              {!store.raffleMode && (
                <Fab
                  size='medium'
                  color='secondary'
                  variant='extended'
                  onClick={() => setShowAddDriver(!showAddDriver)}
                >
                  Fahrer eintragen
                </Fab>
              )}
            </MidContainer>
            <TypeContainer>
              {(!store.raffleMode ||
                (store.raffleMode && store.leagueType === LeagueType.F1)) && (
                <Fab
                  size='medium'
                  color={
                    store.leagueType === LeagueType.F1 ? 'primary' : 'default'
                  }
                  onClick={() => store.setLeagueType(LeagueType.F1)}
                  variant='extended'
                  disabled={store.raffleMode}
                >
                  <img height={35} src={F1Logo} alt='F1' />
                </Fab>
              )}
              {(!store.raffleMode ||
                (store.raffleMode && store.leagueType === LeagueType.F2)) && (
                <Fab
                  size='medium'
                  color={
                    store.leagueType === LeagueType.F2 ? 'primary' : 'default'
                  }
                  onClick={() => store.setLeagueType(LeagueType.F2)}
                  variant='extended'
                  disabled={store.raffleMode}
                >
                  <img height={55} src={F2Logo} alt='F2' />
                </Fab>
              )}
            </TypeContainer>
          </>
        </NavigationContainer>

        <InformationContainer>
          <Typography variant='subtitle1'>
            Fahrer: {store.missingDriver}/{store.maxUserCount}
          </Typography>
          <Typography variant='subtitle1'>
            Teams: {store.raffleData.length}/{store.results.length}
          </Typography>
        </InformationContainer>
      </Container>
    </>
  );
});

const Container = styled.div`
  position: relative;
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'navigation' 'logos';
`;

const LogosContainer = styled.div`
  position: relative;
  grid-area: logos;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr;
`;

const TeamLogo = styled.div`
  position: relative;
  width: 225px;
  height: 225px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #333;

  img {
    width: 225px;
    border-radius: 10px;
  }

  @media only screen and (max-width: 1440px) {
    width: 200px;
    height: 200px;
    img {
      width: 200px;
      border-radius: 10px;
    }
  }
`;

const NavigationContainer = styled.div`
  grid-area: navigation;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 70%;
  background-color: #fff;
  padding: 15px;
  justify-items: center;
  justify-self: center;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  box-shadow: 0px 0px 15px #333;
`;

const MidContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const TypeContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const ChipContainer = styled.div`
  position: absolute;
  text-align: center;
  z-index: 100;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const LogoContainer = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 100px;
`;

const InformationContainer = styled.div`
  position: absolute;
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  gap: 50px;
  bottom: 0;
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 10px 0 5px;
  border-top-left-radius: 100px;
  box-shadow: 0px 0px 15px #fff;
`;

const AddDriverContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 15px;
`;

const DriverContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  .MuiChip-root {
    flex-basis: 20%;
    flex-grow: 1;
    flex-shrink: 0;
  }
`;
