import { create, persist } from 'mobx-persist';
import { makeAutoObservable } from 'mobx';
import { getTeamsByType } from '../utils';

// const mockArray = [['A', 'B'], ['C'], ['D', 'E'], ['F', 'G'], ['H', 'I']];

export enum LeagueType {
  F1 = 'F1',
  F2 = 'F2',
}

export interface Team {
  teamName: string;
  teamLogo: string;
  drivers: string[];
}

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

export const storageName = 'dhdl-data';

export class DataModel {
  isRegistered: boolean = false;
  leagueType: LeagueType = LeagueType.F1;
  raffleIndexes: number[] = [];
  maxUserCount: number = 0;
  @persist raffleMode: boolean = false;
  @persist('list') users: string[][] = [];
  @persist('list') results: Team[] = [];

  constructor() {
    makeAutoObservable(this);
    hydrate(storageName, this);
    this.results = getTeamsByType(this.leagueType);
    this.raffleIndexes = this.results.map((u, i) => i);
  }

  setIsRegistered() {
    this.isRegistered = true;
  }

  clear() {
    this.results = getTeamsByType(this.leagueType);
    this.raffleIndexes = this.results.map((u, i) => i);
    this.users = [];
    this.maxUserCount = 0;
  }

  addUsers(users: string[]) {
    this.users.push(users);
    this.maxUserCount++;
  }

  setLeagueType(type: LeagueType) {
    this.leagueType = type;
    this.results = getTeamsByType(type);
  }

  saveTeamAndDriver(teamIndex: number, drivers: string[]) {
    this.results[teamIndex].drivers = drivers;
    this.users = this.users.filter((u) => u[0] !== drivers[0]);
    this.raffleIndexes = this.raffleIndexes.filter((i) => i !== teamIndex);
  }

  setRaffleMode(mode: boolean) {
    this.raffleMode = mode;
  }

  removeUser(index: number) {
    this.users.splice(index, 1);
    this.maxUserCount--;
  }

  get drivers(): string[][] {
    let filteredUser = this.users.filter((u) => u.length === 2);

    if (filteredUser.length > 0) {
      return filteredUser;
    }
    return this.users;
  }

  get missingDriver(): number {
    return this.users.length;
  }

  get teams(): Team[] {
    return this.results;
  }

  get raffleData(): number[] {
    return this.raffleIndexes;
  }

  get driverEntries(): number {
    let count = 0;
    this.users.forEach((u) => {
      count += u.length;
    });

    return count;
  }
}
