import React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { CreateAndRaffle } from './pages/CreateAndRaffle';
import { theme } from './theme';
import { observer } from 'mobx-react-lite';

export const App = observer(() => {
  return (
    <ThemeProvider theme={theme}>
      <CreateAndRaffle />
    </ThemeProvider>
  );
});
